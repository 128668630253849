import {
  CREATING_PACKAGE,
  UPDATING_PACKAGE,
  PACKAGE_CREATED,
  PACKAGE_UPDATED,
  ERROR_PACKAGE_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";
import moment from "moment";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
};

export const createEntity =
  (formData, tokenData, exitNow, history) => async (dispatch) => {
    dispatch({
      type: CREATING_PACKAGE,
    });


    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );
    
    let webKitFormData = new FormData();

    if (data.categories) {
      for (let i = 0; i < data.categories.length; i++) {
        webKitFormData.append("categories[]", data.categories[i]);
      }
    }
    if (data.faqs) {
      for (let i = 0; i < data.faqs.length; i++) {
        webKitFormData.append("faqs[]", data.faqs[i]);
      }
    }
    if (data.trip_info) {
      for (let i = 0; i < data.trip_info.length; i++) {
        webKitFormData.append("trip_info[]", data.trip_info[i]);
      }
    }
    if (data.banners) {
      let bannerImage = data.banners.map((item) => item.banner_image.file);
      let bannerCaption = data.banners.map((item) => item.banner_caption);
      let bannerAlt = data.banners.map((item) => item.banner_alt);
      for (let i = 0; i < bannerImage.length; i++) {
        webKitFormData.append(
          "banners[" + i + "][banner_image]", bannerImage[i]
        );
      }
      for (let i = 0; i < bannerCaption.length; i++) {
        webKitFormData.append(
          "banners[" + i + "][banner_caption]",
          bannerCaption[i]
        );
      }
      for (let i = 0; i < bannerAlt.length; i++) {
        webKitFormData.append("banners[" + i + "][banner_alt]", bannerAlt[i]);
      }
    }
    if (data.facts) {
      let factsTitle = data.facts.map((item) => item.fact_title);
      let factsValue = data.facts.map((item) => item.fact_value);
      for (let i = 0; i < factsTitle.length; i++) {
        webKitFormData.append("facts[" + i + "][fact_title]", factsTitle[i]);
      }
      for (let i = 0; i < factsValue.length; i++) {
        webKitFormData.append("facts[" + i + "][fact_value]", factsValue[i]);
      }
    }
    if (data.pricegroup) {
      let min_people = data.pricegroup.map((item) => item.min_people);
      let max_people = data.pricegroup.map((item) => item.max_people);
      let unit_price = data.pricegroup.map((item) => item.unit_price);
      let short_description = data.pricegroup.map((item) => item.short_description);
      let offer_unit_price = data.pricegroup.map((item) => item.offer_unit_price);
      let offer_label = data.pricegroup.map((item) => item.offer_label);
      let offer_information = data.pricegroup.map((item) => item.offer_information);
      for (let i = 0; i < min_people.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][min_people]", min_people[i]);
      }
      for (let i = 0; i < max_people.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][max_people]", max_people[i]);
      }
      for (let i = 0; i < unit_price.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][unit_price]", unit_price[i]);
      }
      for (let i = 0; i < short_description.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][short_description]", short_description[i] = '');
      }
      for (let i = 0; i < offer_unit_price.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][offer_unit_price]", offer_unit_price[i] = 0);
      }
      for (let i = 0; i < offer_label.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][offer_label]", offer_label[i] = '');
      }
      for (let i = 0; i < offer_information.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][offer_information]", offer_information[i] = '');
      }
    }
    if (data.departures) {
      let departureDate = data.departures.map((item) => item.departure_date);
      let departureCost = data.departures.map((item) => item.departure_cost);
      let departureNote = data.departures.map((itm) => itm.departure_note);
      let departureStatus = data.departures.map((itm) => itm.departure_status);
      for (let i = 0; i < departureDate.length; i++) {
        webKitFormData.append(
          "departures[" + i + "][departure_date]",
          moment(departureDate[i]).format("YYYY-MM-DD")
        );
      }
      for (let i = 0; i < departureNote.length; i++) {
        webKitFormData.append(
          "departures[" + i + "][departure_note]",
          departureNote[i]
        );
      }
      for (let i = 0; i < departureStatus.length; i++) {
        webKitFormData.append(
          "departures[" + i + "][departure_status]",
          departureStatus[i]
        );
      }
      for (let i = 0; i < departureCost.length; i++) {
        webKitFormData.append(
          "departures[" + i + "][departure_cost]",
          departureCost[i]
        );
      }
    }
    

    ["departures", "facts", "categories", "banners", "faqs", "pricegroup", "trip_info"].forEach(
      (e) => delete data[e]
    );

    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });

    await axios
      .post(`${BASE_URL}api/v1/admin/travel/package/add`, webKitFormData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: PACKAGE_CREATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        message.success(response.data.message);
        if (exitNow) {
          history.push("/packages");
        }
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_PACKAGE_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };

export const updateEntityInfo =
  (formData, tokenData, id) => async (dispatch) => {
    dispatch({
      type: UPDATING_PACKAGE,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );
    let webKitFormData = new FormData();

    if (data.categories) {
      for (let i = 0; i < data.categories.length; i++) {
        webKitFormData.append("categories[]", data.categories[i]);
      }
    }
    if (data.faqs) {
      for (let i = 0; i < data.faqs.length; i++) {
        webKitFormData.append("faqs[]", data.faqs[i]);
      }
    }
    if (data.trip_info) {
      for (let i = 0; i < data.trip_info.length; i++) {
        webKitFormData.append("trip_info[]", data.trip_info[i]);
      }
    }
    if (data.banners) {
      let bannerImage = data.banners.map((item) => item.banner_image?.file);
      let bannerCaption = data.banners.map((item) => item.banner_caption);
      let bannerAlt = data.banners.map((item) => item.banner_alt);
      let bannerId = data.banners.map((item) => item.id);
      for (let i = 0; i < bannerImage.length; i++) {
        webKitFormData.append(
          "banners[" + i + "][banner_image]", bannerImage[i]
        );
      }
      for (let i = 0; i < bannerCaption.length; i++) {
        webKitFormData.append(
          "banners[" + i + "][banner_caption]",
          bannerCaption[i]
        );
      }
      for (let i = 0; i < bannerAlt.length; i++) {
        webKitFormData.append("banners[" + i + "][banner_alt]", bannerAlt[i]);
      }
      for (let i = 0; i < bannerId.length; i++) {
        webKitFormData.append("banners[" + i + "][id]", bannerId[i]);
      }
    }
    if (data.facts) {
      let factsTitle = data.facts.map((item) => item.fact_title);
      let factsValue = data.facts.map((item) => item.fact_value);
      let factsId = data.facts.map((item) => item.id);
      for (let i = 0; i < factsTitle.length; i++) {
        webKitFormData.append("facts[" + i + "][fact_title]", factsTitle[i]);
      }
      for (let i = 0; i < factsValue.length; i++) {
        webKitFormData.append("facts[" + i + "][fact_value]", factsValue[i]);
      }
      for (let i = 0; i < factsId.length; i++) {
        webKitFormData.append("facts[" + i + "][id]", factsId[i]);
      }
    }
    if (data.pricegroup) {
      let min_people = data.pricegroup.map((item) => item.min_people);
      let max_people = data.pricegroup.map((item) => item.max_people);
      let unit_price = data.pricegroup.map((item) => item.unit_price);
      let short_description = data.pricegroup.map((item) => item.short_description);
      let offer_unit_price = data.pricegroup.map((item) => item.offer_unit_price);
      let offer_label = data.pricegroup.map((item) => item.offer_label);
      let offer_information = data.pricegroup.map((item) => item.offer_information);
      let id = data.pricegroup?.map((item) => item.id);
      for (let i = 0; i < min_people.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][min_people]", min_people[i]);
      }
      for (let i = 0; i < max_people.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][max_people]", max_people[i]);
      }
      for (let i = 0; i < unit_price.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][unit_price]", unit_price[i]);
      }
      for (let i = 0; i < short_description.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][short_description]", short_description[i] = '');
      }
      for (let i = 0; i < offer_unit_price.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][offer_unit_price]", offer_unit_price[i] = 0);
      }
      for (let i = 0; i < offer_label.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][offer_label]", offer_label[i] = '');
      }
      for (let i = 0; i < offer_information.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][offer_information]", offer_information[i] = '');
      }
      for (let i = 0; i < id.length; i++) {
        webKitFormData.append("pricegroup[" + i + "][id]", id[i]);
      }
    }

    if (data.departures) {
      let departureDate = data.departures.map((item) => item.departure_date);
      let departureCost = data.departures.map((item) => item.departure_cost);
      let departureNote = data.departures.map((itm) => itm.departure_note);
      let departureStatus = data.departures.map((itm) => itm.departure_status);
      let departureId = data.departures.map((itm) => itm.id);
      for (let i = 0; i < departureDate.length; i++) {
        webKitFormData.append(
          "departures[" + i + "][departure_date]",
          moment(departureDate[i]).format("YYYY-MM-DD")
        );
      }
      for (let i = 0; i < departureNote.length; i++) {
        webKitFormData.append(
          "departures[" + i + "][departure_note]",
          departureNote[i]
        );
      }
      for (let i = 0; i < departureStatus.length; i++) {
        webKitFormData.append(
          "departures[" + i + "][departure_status]",
          departureStatus[i]
        );
      }
      for (let i = 0; i < departureCost.length; i++) {
        webKitFormData.append(
          "departures[" + i + "][departure_cost]",
          departureCost[i]
        );
      }
      for (let i = 0; i < departureId.length; i++) {
        webKitFormData.append(
          "departures[" + i + "][id]",
          departureId[i]
        );
      }
    }

    ["departures", "facts", "categories", "banners", "faqs", "pricegroup", "trip_info"].forEach(
      (e) => delete data[e]
    );

    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });
   webKitFormData.append("_method", "put");

    await axios
      .post(
        `${BASE_URL}api/v1/admin/travel/package/edit/${id}`, webKitFormData, {
          headers: {
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then(function (response) {
        dispatch({
          type: PACKAGE_UPDATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_PACKAGE_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };
